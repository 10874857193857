@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f0f7fa; /* Light grey */
    border-top: 10px solid #257ba3; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    position: fixed;
    top: 50vh;
    width: 80px;
    height: 80px;
    padding: 1em;
    left: calc(50% - 40px);
    z-index: 30;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 8px 10px rgba(100, 152, 182, 0.2);
  }

  @media (min-width: 768px) {
    .spinner-container {
      top: 25vh;
    }
  }