/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');

* {
  box-sizing: border-box;
}

html,body
{
  width: 100%;
  margin: 0;
}

body {
  font-family: 'Sofia Sans', 'Open Sans', 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(49, 49, 49);
  text-align: center;
  margin-top: 0.5em;
}

h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

h1.colored {
  color: #066591;
}

h2 {
  margin:1.5em 0 1em;
}

h4 {
  margin-bottom: 0.7em;
}

a:link, a:visited {
  color:#066591;
}

a:hover {
  color:#2e8cb8
}

main {
  margin:2rem auto;
  text-align: center;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-print {
  text-align: center;
}

.left {
  text-align: left;
}

.flex-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-left .multi-inp {
  margin:0.6em 0;
}

.flex-left label {
  text-align: left;
  max-width: fit-content;
}

.left-short {
  text-align: left;
  margin: 0 auto 0.8rem auto;
  max-width: 25rem;
}

.left-short label {
  font-weight: 100;
  color: #333333;
}

.control-single-line {
  padding: 0.7em 0.5em;
}

.control-single-line label {
  margin: 0 0.5em 0 0;
}

.control-select {
  width: 100%;
  margin-top:0.5em;
}

.bolded {
  font-weight: bold;
  color: #066591;
}

.alert-def, .alert-ok, .alert-warn, .alert-ab {
  padding-left: 0.5em;
}

.alert-def, .alert-ab, .alert-not-ok {
  color:rgb(160, 0, 0);
}

.alert-ok {
  color:rgb(76, 136, 7);
}

.alert-warn {
  color:rgb(211, 172, 0);
}

.alert-smallerValue {
  color:rgb(0, 125, 163);
}

/* .alert-def::before, .alert-ok::before, .alert-warn::before, .alert-ab::before, .alert-not-ok::before {
  font-family: "Font Awesome 5 Free";
  padding-right: 5px;
}

.alert-def::before {
  content: "\f358";
}

.alert-ok::before {
  content: "\f118";
}

.alert-warn::before {
  content: "\f0f3";
}

.alert-ab::before {
  content: "\f35b";
}

.alert-not-ok::before {
  content: "\f119";
} */

.form {
  width: 95%;
  margin: 2rem auto;
}

.form-img {
  width: 100%;
  margin: 1.2em auto;
}

.error-message {
  color:rgb(160, 0, 0);
  margin-top: 0;
}

.control .error-input {
  background-color: #fdeeee;
  border: 1px solid rgb(160, 0, 0)
}

.control {
  margin: 0 auto 0.5rem auto;
  max-width: 25rem;
}

.multi-inp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.multi-inp.cols {
  justify-content:left;
}

.multi-inp.wider {
  max-width: 100%;
  flex-wrap: wrap;
}

.multi-inp.wider.top {
  align-items: start;
}

.multi-inp.wider.top .multi-inp p {
  text-align: center;
}

.multi-inp.wider .card {
  display: flex;
  align-items: center;
}

.vital .multi-inp {
  justify-content: left;
  margin-left: 0.6em;
}

.vital p {
  text-align: justify;
}

.vital p label {
  margin-left: 0;
}

.card-mini {
  border: 1px solid #c1dfec;
  width:10em;
  height:11em;
  border-radius: 8px;
  padding:1em;
}

.card-mini h3 {
  margin-top: 0;
}

.bottom-aligned, .bottom-aligned.multi-inp.wider .card {
  align-items: flex-end;
}

.multi-inp.wider .card:empty {
  height: 0;
  width: 33%;
}

.half, .third {
  width:98%;
}

.third p {
  margin-bottom: 0;
}

.logo-img {
  max-height: 150px;
}

.muscles-img {
  height: 600px;
  padding: 0.8em;
}

.muscles-img.view {
  height: 480px;
}

.feet-img {
  max-width: 98%;
}
.xRay-img {
  max-width: 98%;
}

.goniometry-img {
  width: 300px;
}

.goniometry-img-s {
  width: 200px;
}

.control-img {
  max-height: 200px;
  margin:0 0.5em;
}

.outlined-img-alert {
  border: 2px solid red;
  color:red;
}

.outlined-img-ok {
  border: 2px solid #71c0e4;
  color: #71c0e4;
}

.card-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin:0.5em auto;
}

/* .card-imgs .feet.left-col {
  margin-right: 2em;
} */

.card-imgs .spine-img span {
  display: block;
  width:160px;
}

.card-imgs .spine-img img {
  width:160px;
}

.card-imgs .varus img {
  width:150px;
}

.card-imgs img {
  margin:0.5em;
}

.subsection-icon {
  width:3em;
}

.control.short {
  width: 48%;
  display: inline-block;
  margin: 0.2em;
}

.control.short label {
  margin-top: 0.5em;
}

.control.short h3 {
  margin-top: 0;
}

.control input, .control input[type="radio"], .control input[type="checkbox"] {
  margin:0 auto;
}

.control input.tiny {
  width:50px;
  display: inline-block;
}

.right-col-imgs {
  margin-top:0.8em;
}

label {
  font-weight: bold;
  margin: 0.5rem 0.6rem;
  color: #066591;
}

.control label {
  display: block;
}

.control input, .control select, .control textarea {
  display: block;
  font: inherit;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #69b0d1;
  padding: 0.25rem;
  background-color: #f0f7fa;
}

.control input:focus {
  outline: none;
}

.control input[type="radio"], .control input[type="checkbox"] {
  display: inline;
  width:auto;
}

.control input[type="file"]::file-selector-button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  margin: 0 0.25rem;
  background-color: transparent;
  border: 1px solid #066591;
  color: #066591;
}

.control input[type="file"]::file-selector-button:hover {
  background-color: #71c0e4;
  color:#ffffff;
  border-color:#71c0e4;
}

.control .lbl-radio, .control .lbl-checkbox {
  display: inline-block;
  color:#333333;
  font-weight: normal;
}

.lbl-datepicker {
  display: inline-block;
  margin: 0 0.5rem 0.5rem;
}

.action {
  margin: 1.5rem 0;
}

.action button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #066591;
  color: white;
  border: 1px solid #257ba3;
  margin: 0.25rem;
}

.action button:hover {
  background-color: #257ba3;
  border-color: #257ba3;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
}

.action button.cancel {
  background-color: transparent;
  border: 1px solid #066591;
  color: #066591;
}

.action button.cancel:hover {
  background-color: #add6e9;
  border:1px solid #add6e9;;
}

.action.in-title {
  display: inline-block;
  margin: 0.4rem 0.4rem 0.4rem 1rem;
  vertical-align: middle;
}

.action.in-title button {
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding:0;
  margin: 0;
  border:0;
}

.action.in-title button:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #b5d5e4;
}

.action.in-title button.edit {
  background: url('/public/edit.png') no-repeat center center;
  background-size: 20px;
}

.action.in-title button.delete {
  background: url('/public/delete.png') no-repeat center center;
  background-size: 20px;
}

.lists {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  animation: sheets-appear 0.5s ease-out forwards;
}

.back {
  margin:0;
}

.back button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #066591;
  color: #066591;
}

.back button:hover {
  background-color: #add6e9;
  border:1px solid #add6e9;;
}

.filter-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  background-color: #f0f7fa;
  padding:1rem;
  margin:1rem 0;
  border: 1px solid #c1dfec;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
}

.filter-wrapper .control {
  width: auto;
  min-width: 16em;
  margin: 0.5rem;
}

.filter-wrapper .control input, .filter-wrapper .control select {
  background-color: #ffffff;
}

.filter-wrapper .action {
  margin: 0.5rem;
}

.filter-wrapper .action button {
  margin: 0 0.25rem;
}

.filter-wrapper .action button.cancel, button.prev-next-step {
  background-color: #71c0e4;
  color:#ffffff;
  border-color:#71c0e4;
}

.filter-wrapper .action button.cancel:hover {
  background-color: #85c7e6;
  border-color: #85c7e6;
}

.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 1.5rem 0 0.5rem;
  padding: 0;
}

.steps li {
  border-right: 1px dashed #b7d7e6;
  color: rgb(49, 49, 49);
  display: flex;
  padding: 0.6rem 1.2rem;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  align-items: center;
  width: min-content;
}

.steps li:last-child {
  border: none;
}

.steps li.active {
  color: #71c0e4;
  border-bottom: 4px solid #71c0e4;
}

.important-info-wrapper {
  width: 100% auto;
  background-color: #f0f7fa;
  padding:1rem;
  margin:1rem 0;
  text-align: center;
  border: 1px solid #c1dfec;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
}

table.simple-tbl td, table.simple-tbl th {
  text-align: center;
  padding: 0.8em;
}

.clogo-thumb {
  max-width: 120px;
  max-height: 50px;
  vertical-align: middle;
}

.cdesc {
  max-width: 18em;
}

@keyframes sheets-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.radio-img-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em 0;
}
.radio-img-wrapper .container {
  height: 310px;
  width: 170px;
  margin: 0.5em;
  background-color: #ffffff;
  box-shadow: 0 0 25px rgba(17, 1, 68, 0.08);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}
.radio-img-wrapper .container label {
  margin: 0;
}
.radio-img-wrapper.sup-pro .container {
  height: 225px;
  width: 90px;
  margin: 0.5em;
}
.radio-img-wrapper.spine-curv .container {
  height: 400px;
  width: 120px;
  margin: 0.5em;
}
.radio-img-wrapper.spine-curv p {
  font-size: 0.9em;
  margin:0.2em 0.3em;
}
.radio-img-wrapper input[type="radio"], .radio-img-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.radio-img-wrapper input[type="radio"]:after, .radio-img-wrapper input[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-size: 20px;
  color: #2192c7;
  right: 10px;
  top: -5px;
}
.radio-img-wrapper input[type="radio"]:after {
  content: "\f111";
}
.radio-img-wrapper input[type="checkbox"]:after {
  content: "\f0c8";
}
.radio-img-wrapper input[type="radio"]:checked:after, .radio-img-wrapper input[type="checkbox"]:checked:after {
  font-weight: 900;
  color: #2192c7;
}
.radio-img-wrapper input[type="radio"]:checked:after {
  content: "\f058";
}
.radio-img-wrapper input[type="checkbox"]:checked:after {
  content: "\f14a";
}
.radio-img-wrapper .container img {
  width: 85%;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

button.del {
  background: url('../public/delete.png') no-repeat center center;
  background-size: 20px;
  width: 40px;
  height: 40px;
  border:0;
  cursor:pointer;
  padding: 0;
  margin-top:0.8em;
}

button.del:hover {
  border: 1px solid #b5d5e4;
  border-radius:4px;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
}

.dates {
  font-size: 0.9em;
  color: #333333;
}
.scale {
  width: 100%;
  height: 1.5em;
  margin: 1em 0;
  display: flex;
  position: relative;
}

.scale .segment {
  width: 33.3%;
}

.scale .segment.blue {
  background-color: #67c1eb;
}

.scale .segment.green {
  background-color: #9bdb66;
}

.scale .segment.red {
  background-color: #db6262;
}

.card-error, .card-message {
  padding:0.5rem 1rem;
  margin:1rem 0;
  border-radius: 6px;
  text-align: center;
}

.card-error {
  color: rgb(160, 0, 0);
  background-color: #fff7f7;
  border: 1px solid #ecc1c1;
  box-shadow: 0 1px 4px rgba(182, 100, 100, 0.2);
}

.card-message {
  color:rgb(196, 104, 0);
  background-color: #fff7e9;
  border: 1px solid #ecd8c1;
  box-shadow: 0 1px 4px rgba(182, 146, 100, 0.2);
}

.example-thumb {
  width:32px;
  height:32px;
  position: relative;
  top:-20px;
  font-size: 0;
  cursor: pointer;
  background-image: url('pages/Diagnostics/Images/pin.png');
}

.example-thumb.active {
  outline:none;
}

.ot-measurements p {
  margin: 0 auto 1em;
  width: 80%;
}

.svg-container {
  width: 100%;
  max-width: 140px;
  height: auto;
  margin-right: 3px;
  display: inline-block;
}

svg path {
  stroke: #ffffff;
}

.svg-edit svg path:hover {
  opacity: 0.7;
}

@media all {
  .page-break {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .wide-col {
    width:60%;
    margin: 0 auto;
  }
  .half {
    width:48%;
  }
  .third {
    width:32%;
  }
  .control input.tiny {
    width:58px;
  }
  .feet-img {
    max-width: 500px;
  }
  .xRay-img {
    max-height: 432px;
  }
  .card-imgs .feet.left-col {
    margin-right: 2em;
  }
  .svg-container {
    max-width: 180px;
  }
}

@page {
  size: auto;
  margin: 10mm 4mm;
}

.s-red {
  fill: #006edc;
}

.therm {
  padding: 0.8em;
  margin: 1em;
  border: 1px solid #dddddd;
  border-radius: 10px;
  align-items: start;
}

.multi-inp.therm .left-col {
  width: 260px;
  margin: 0 1em 0 0;
}

.therm .pic {
  max-width: 250px;
  max-height: 250px;
}

.therm .degrees {
  width:40px;
  height: 348px;
  text-align: center;
}

.therm .degrees .value {
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 2px 0;
  border: 1px solid #dddddd;
  border-radius: 5px;
  text-align: center;
  background-color: #eeeeee;
  color: #6c6c6c;
}

.row-value {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c1dfec;
  border-radius: 8px;
  margin: 0.5em 0 1em;
}

.row-value>span {
  padding: 0.6em;
}

.row-value .data {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.row-icon-d {
  color: #77c0df;
  font-weight: bold;
  padding: 0.6em;
  background-color: #f0f7fa;
}

.row-icon-d.left {
  border-radius: 8px 0 0 8px;
}

.row-icon-d.right {
  border-radius: 0 8px 8px 0;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .page-break {
    display: block;
    break-before: always;
  }
  h2 {
    margin-top: 0.4em;
  }
  /* h3 {
    margin-top: 1em;
  } */
  .multi-inp.wider.top.therm-container {
    display: block;
  }
  .multi-inp.therm {
    display: inline-block;
  }
  .therm {
    width: 48%;
    margin: 0.4em;
  }
  .multi-inp.therm .left-col {
    width: 240px;
    margin: 0 1em 0 0;
    float: left;
  }
  .multi-inp.therm .left-col h3 {
    font-size: medium;
  }
  .therm .pic {
    max-width: 240px;
    max-height: 240px;
  }
  .therm .degrees {
    float: right;
  }
  .svg-container {
    max-width: 160px;
  }
  .row-value {
    margin: 0.3em 0 0.8em;
  }
  .goniometry-section h3 {
    margin-top: 1em;
  }
  .goniometry-img {
    width: 250px;
  }
  .goniometry-img-s {
    width: 180px;
  }
  .half {
    width:48%;
  }
  .third {
    width:33%;
  }
  .feet-img {
    height: 250px;
    /* width: 100%; */
  }
  .xRay-img {
    height: 230px;
  }
  .card-imgs {
    text-align: center;
  }
  .card-imgs .feet h3 {
    margin-bottom: 0.5em;
  }
  .control.multi-inp.cols.wider {
    justify-content: center;
  }
  .card-mini {
    text-align: center;
  }
  .muscles-img.view {
    height: 450px;
  }
  .card-imgs .spine-img img {
    width:90px;
    margin:0 0.2em;
  }
  .card-imgs .feet.left-col {
    margin-right: 2em;
  }
  .card-imgs .feet img{
    width:45px;
  }
  .card-imgs .varus img {
    width:120px;
  }
  .control-single-line {
    padding: 0.15em 0.5em;
  }
  .left.feet-section p {
    margin-top:0.3em;
    margin-bottom:0.3em;
  }
  .left.spine-section p {
    margin-top:0.2em;
    margin-bottom:0.2em;
  }
  .recommendations {
    margin-top: 0.2em;
  }
  h3.recommendations-h {
    margin-bottom: 0.2em;
  }
  .form-img {
    width: 50%;
    margin: 0 auto;
  }
  .ot-measurements h4 {
    margin-bottom: 0;
  }
  .ot-measurements p {
    margin: 0 auto 0.3em;
    width: 80%;
  }
  .important-info-wrapper {
    padding:0.8rem;
    margin:0.7rem;
  }
}