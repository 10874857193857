table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
}

thead {
  color: #f0f7fa;
  background-color: #71c0e4;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
}

tr {
  width: 100%;
  padding:0.5rem;
  border-bottom: 1px solid #dbecf5;
}

td, th {
  border: 0;
  padding:0.2rem 0.5rem;
  text-align: left;
}

td {
  height:3em;
}

.actions {
  text-align: right;
}

button.edit, button.delete {
  width: 40px;
  height: 40px;
  padding:0;
  border:0;
}

button.edit:hover, button.delete:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #b5d5e4;
  border-radius:4px;
  box-shadow: 0 1px 4px rgba(100, 152, 182, 0.2);
  cursor:pointer;
}

button.edit {
  background: url('../../../public/edit.png') no-repeat center center;
  background-size: 20px;
}

button.delete {
  background: url('../../../public/delete.png') no-repeat center center;
  background-size: 23px;
}

th {
  padding:0.8rem;
}

tbody tr:hover {
  background-color: #f1faff;
}

@media screen and (max-width: 600px) {
  tr { 
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
    td, th {
    flex: 1 1 150px;
    text-align: center;
  }
    .actions {
    text-align: center;
  }
}